import React from 'react';
import { Typography, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, PageContainer } from '../components/GlobalStyledComponents'; 
import { ActionButton } from '../components/ActionButton'; 
import Header from '../components/Header';

export default function StoreDetails() {
  const location = useLocation(); // Obtemos os dados da loja passados pelo state
  const { store, coordinates } = location.state || {}; // Pegamos o store e as coordenadas do state
  const navigate = useNavigate();

  if (!store) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6">Erro: Detalhes da loja não disponíveis.</Typography>
      </Box>
    );
  }
  const apiUpload = process.env.REACT_APP_API_SGR_UPLOAD;

  const imageUrl = store.img ? `${apiUpload}/${store.img}` : null;

  const openMap = () => {
    if (coordinates) {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${coordinates.lat},${coordinates.lng}`;
      window.open(url, '_blank');
    } else {
      alert("As coordenadas de localização não estão disponíveis.");
    }
  };

  return (
    <PageContainer>
      <Header />
      <Container>
        <Typography variant="h4" component="h1" gutterBottom textAlign="center">
          {store.name || "Detalhes da Loja"}
        </Typography>

        {imageUrl && (
          <Box 
            component="img" 
            src={imageUrl} 
            alt={`Imagem da loja ${store.name}`} 
            sx={{ width: '100%', height: 'auto', mb: 2 }} 
          />
        )}

        <Typography variant="body1" gutterBottom>
          {store.description || "Descrição não disponível"}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Telefone: {store.phone || "Telefone não disponível"}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Contato: {store.email || "Contato não disponível"}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Endereço: {store.address || "Endereço não disponível"}
        </Typography>

        <ActionButton
          label="Abrir no Google Maps"
          onClick={openMap}
          sx={{ mt: 3 }}
        />

        <ActionButton
          label="Voltar"
          onClick={() => navigate(-1)}
          variant="outlined"
          sx={{ mt: 2 }}
        />
      </Container>
    </PageContainer>
  );
}