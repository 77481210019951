import React from 'react';
import PartnerList from '../components/PartnerLIst';

export default function PartnerEcopoints() {
  return (
    <PartnerList
      type="ecopontos parceiros"
      title="Ecopontos"
      description="Lista de Ecopontos parceiros programa de pontos. Para ganhar pontos, realize o descarte correto de seus resíduos recicláveis e especiais e ganhe pontos para trocar em produtos nas lojas parceiras"
      apiEndpoint="ecopoints" // Endpoint para ecopontos
      noItemsMessage="Nenhum ecoponto parceiro disponível no momento."
    />
  );
}