import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/apiService';
import { Container, PageContainer } from '../components/GlobalStyledComponents';
import Header from '../components/Header';
import OperatorTransactionFilterCard from '../components/OperatorTransactionFilterCard'; // Novo componente
import StoreInfoCard from '../components/StoreInfoCard'; 
import QuickOptionsContainer from '../components/QuickOptionContainer'; 
import RecyclingIcon from '@mui/icons-material/Recycling';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

export default function OperatorHome() {
  const [user, setUser] = useState(null); 
  const [store, setStore] = useState(null); 
  const [transactions, setTransactions] = useState([]); 
  const [filter, setFilter] = useState('all');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const tokenEcoponto = localStorage.getItem('tokenEcoponto');


  const fetchOperatorData = async () => {
    try {
      const userInfo = await apiService.get('users/me', tokenEcoponto);
      setUser(userInfo)
      if (userInfo.store) {
        setStore(userInfo.store); 
        const storeTransactions = await apiService.get(`partner-stores/${userInfo.store.id}/transactions`, tokenEcoponto); 
        
        const formattedTransactions = [
          ...storeTransactions.donations.map(donation => ({
            type: 'donation',
            description: `Compra Realizada: ${donation.description}. Doador/Vendedor: ${donation.user.name}`,
            points: -donation.value, // Doação é negativa
            date: new Date(donation.createdAt),
          })),
          ...storeTransactions.redemptions.map(redemption => ({
            type: 'redeem',
            description: `Venda Realizada: ${redemption.description}. Comprador: ${redemption.user.name}`,
            points: redemption.points, // Resgate é positivo
            date: new Date(redemption.createdAt),
          })),
        ];

        const sortedTransactions = formattedTransactions.sort((a, b) => b.date - a.date);
        setTransactions(sortedTransactions);
      } else {
        setErrorMessage('Nenhuma loja vinculada a este operador.');
      }
    } catch (error) {
      console.error('Erro ao carregar dados do operador:', error);
      setErrorMessage('Erro ao carregar dados do operador.');
    }
  };

  useEffect(() => {
    fetchOperatorData();
  }, []);

  const handleRegisterDonation = () => {
    navigate('/registrar-doacao');
  };

  const handleRegisterRedemption = () => {
    navigate('/registrar-compra');
  };

  const quickOptions = [
    {
      label: 'Doação',
      icon: RecyclingIcon,
      onClick: handleRegisterDonation,
    },
    {
      label: 'Resgate',
      icon: ShoppingBagIcon,
      onClick: handleRegisterRedemption,
    },
  ];

  return (
    <PageContainer>
      <Header />
      <Container>
        {store ? (
          <>
            <Typography variant="h5" textAlign="center" gutterBottom>
              Bem-vindo, {user.name}
            </Typography>

            <StoreInfoCard store={store} />

            <QuickOptionsContainer options={quickOptions} />

            <OperatorTransactionFilterCard
              transactions={transactions}
              filter={filter}
              setFilter={setFilter}
            />
          </>
        ) : (
          <Typography variant="h5" textAlign="center">
            {errorMessage || 'Carregando...'}
          </Typography>
        )}
      </Container>
    </PageContainer>
  );
}