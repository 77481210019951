import axios from 'axios';

/**
 * Busca o endereço com base no CEP utilizando a API ViaCEP.
 * @param {string} cep - O CEP a ser pesquisado.
 * @returns {Object} - Objeto contendo rua, bairro, cidade e estado.
 * @throws {Error} - Caso o CEP seja inválido ou ocorra um erro na requisição.
 */
const getAddress = async (cep) => {
  const formattedCep = cep.replace(/\D/g, ''); // Remove qualquer caractere não numérico do CEP

  if (formattedCep.length !== 8) {
    throw new Error('CEP inválido');
  }

  try {
    const response = await axios.get(`https://viacep.com.br/ws/${formattedCep}/json/`);

    if (response.data.erro) {
      throw new Error('CEP não encontrado');
    }

    const { logradouro, bairro, localidade, uf } = response.data;

    return {
      street: logradouro,
      neighborhood: bairro,
      city: localidade,
      state: uf,
    };
  } catch (error) {
    throw new Error('Erro ao buscar o endereço. Verifique o CEP ou tente novamente mais tarde.');
  }
};

export default getAddress;