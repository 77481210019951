import React, { useEffect, useState } from 'react';
import { ListItem, ListItemText, Button, Typography, CircularProgress, Avatar } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// Função para obter latitude e longitude a partir do endereço
async function getLatLngFromAddress(address) {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.status === 'OK') {
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lng };
    } else {
      console.error('Geocodificação falhou:', data.status);
      return null;
    }
  } catch (error) {
    console.error('Erro ao buscar coordenadas:', error);
    return null;
  }
}

// Função para calcular a distância entre duas coordenadas (fórmula de Haversine)
function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371;
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    0.5 - Math.cos(dLat) / 2 +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * (1 - Math.cos(dLon)) / 2;

  return R * 2 * Math.asin(Math.sqrt(a));
}

export default function StoreListItem({ store, location, navigate }) {
  const [coordinates, setCoordinates] = useState(null);
  const apiUpload = process.env.REACT_APP_API_SGR_UPLOAD;

  const imageUrl = store.img ? `${apiUpload}/${store.img}` : null;

  useEffect(() => {
    async function fetchCoordinates() {
      const result = await getLatLngFromAddress(store.address);
      if (result) {
        setCoordinates(result);
      }
    }

    fetchCoordinates();
  }, [store.address]);

  const distance = location && coordinates
    ? calculateDistance(location.latitude, location.longitude, coordinates.lat, coordinates.lng).toFixed(2)
    : null;

  return (
    <ListItem 
      button 
      onClick={() => navigate(`/store-details`, { state: { store, coordinates } })} // Passa o store completo
      sx={{ marginBottom: 2, backgroundColor: '#f9f9f9', borderRadius: 1 }}
    >
      {imageUrl && (
        <Avatar
          src={imageUrl}
          alt={`Imagem da loja ${store.name}`}
          sx={{ width: 50, height: 50, marginRight: 2 }} // Tamanho do ícone da loja
        />
      )}
      <ListItemText 
        primary={store.name}
        secondary={
          <>
            <Typography component="span" variant="body2" color="textPrimary">
              {store.description}
            </Typography>
            <br />
            <Typography component="span" variant="body2" color="textSecondary">
              {store.address} {/* Mostra o endereço completo */}
            </Typography>
            <br />
            <Typography component="span" variant="body2" color="textSecondary">
              {coordinates && distance ? `${distance} km de distância` : 'Localização desconhecida'}
            </Typography>
          </>
        }
      />
      <Button
        startIcon={<LocationOnIcon />}
        onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${coordinates?.lat},${coordinates?.lng}`, '_blank')}
        disabled={!coordinates}
        sx={{ ml: 2 }}
      >
        Ver no Mapa
      </Button>
      {!coordinates && <CircularProgress size={20} sx={{ ml: 2 }} />} {/* Loading enquanto busca as coordenadas */}
    </ListItem>
  );
}