import React from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';

const SectionTitle = styled(Typography)`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 1rem;
  text-align: center;
`;

const SectionDescription = styled(Typography)`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const PageTitle = ({ title, description }) => (
  <>
    <SectionTitle variant="h5">{title}</SectionTitle>
    <SectionDescription variant="subtitle1">{description}</SectionDescription>
  </>
);