import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar, Alert  } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Remova as chaves na importação
import apiService from '../services/apiService';
import { PageTitle } from '../components/PageTitle';
import { ActionButton } from '../components/ActionButton';
import { SuccessDialog } from '../components/SuccessDialog';
import { Container, PageContainer } from '../components/GlobalStyledComponents';
import Header from '../components/Header';

const Register = () => {
  const [ecopointName, setEcopointName] = useState('');
  const [location, setLocation] = useState('');
  const [pointsGained, setPointsGained] = useState(0);
  const [newBalance, setNewBalance] = useState(0);  // Adiciona o novo saldo ao estado
  const [userName, setUserName] = useState('');  // Adiciona o nome do usuário ao estado
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [error, setError] = useState(''); // Mensagem de erro da API
  const [openSnackbar, setOpenSnackbar] = useState(false);  // Controle para o Snackbar
  const navigate = useNavigate();
  const { ecopointId } = useParams();

  useEffect(() => {
    const fetchEcopointDetails = async () => {
      try {
        const response = await apiService.get(`ecopoints/${ecopointId}`);
        setEcopointName(response.name || '');
        setLocation(response.address || 'Endereço não disponível');
        setPointsGained(response.points || 10);
      } catch (error) {
        setError('Erro ao carregar os detalhes do Ecoponto.');
        setOpenSnackbar(true); // Mostra o Snackbar no caso de erro
      }
    };

    fetchEcopointDetails();
  }, [ecopointId]);

  const handleRegister = async () => {
    try {
      const tokenEcoponto = localStorage.getItem('tokenEcoponto');
      const decodedtokenEcoponto = jwtDecode(tokenEcoponto);
      const cpf = decodedtokenEcoponto.cpf.replace(/\D/g, '');

      const result = await apiService.post('disposals', { ecopointId, cpf, points: pointsGained }, tokenEcoponto);

      // Atualiza o estado com os dados retornados pela API
      setUserName(result.userName);
      setNewBalance(result.newBalance);
      setOpenSuccessDialog(true);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Erro ao registrar o descarte.';
      
      if (errorMessage === 'Você só pode fazer um descarte por hora.') {
        setError('Você já realizou um descarte recentemente. Tente novamente em uma hora.');
      } else if (errorMessage === 'Unauthorized') {
        setError('Seu tokenEcoponto expirou. Por favor, faça login novamente.');
        localStorage.removeItem('tokenEcoponto');
        navigate('/login');
      } else {
        setError(errorMessage);
      }
      setOpenSnackbar(true); // Mostra o Snackbar em caso de erro
    }
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    navigate('/');
  };

  const handleErrorClose = () => {
    setError('');
    setOpenSnackbar(false); // Fecha o Snackbar
  };

  return (
    <PageContainer>
      <Header />
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 3,
            textAlign: 'center',
            gap: 3,
            maxWidth: '700px',
            margin: '0 auto',
          }}
        >
          <PageTitle 
            title={ecopointName} 
            description={`Localizado em: ${location}`} 
            titleStyle={{ fontSize: '2.4rem', fontWeight: 'bold' }}
          />

          <Typography variant="body1" sx={{ fontSize: '1.2rem', lineHeight: '1.6', color: '#333', marginBottom: '20px' }}>
            Ao realizar o descarte dos seus resíduos neste ecoponto, você faz parte de um movimento de <strong>economia circular</strong>. 
            Isso significa que os materiais que seriam descartados voltarão a ter valor, contribuindo para a preservação dos nossos recursos naturais.
            
          </Typography>

          <ActionButton
            label="Registrar Descarte"
            onClick={handleRegister}
            sx={{ marginBottom: '20px', width: '100%', maxWidth: '400px' }}
          />

          <ActionButton
            label="Voltar"
            onClick={() => navigate('/')}
            variant="outlined"
            sx={{ width: '100%', maxWidth: '400px' }}
          />
        </Box>

        <SuccessDialog
          open={openSuccessDialog}
          onClose={handleCloseSuccessDialog}
          userName={userName}
          ecopointName={ecopointName}
          pointsGained={pointsGained}
          newBalance={newBalance}
        />

        {/* Snackbar de erro */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleErrorClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </Container>
    </PageContainer>
  );
};

export default Register;