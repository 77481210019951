import React from 'react';
import { Box, List, ListItem, ListItemText, Divider, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const FilterButton = styled(Button)`
  padding: 6px 12px;
  font-size: 0.875rem;
  text-transform: none;
  margin: 4px;
  flex: 1 1 20%;
  
  @media (max-width: 600px) {
    font-size: 0.75rem;
    flex: 1 1 45%;
  }
`;

const FilterContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const SectionTitle = styled(Box)`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 8px;
  text-align: center;
`;

const OperatorTransactionFilterCard = ({ transactions, filter, setFilter }) => {
  const filteredTransactions = transactions.filter(
    (transaction) => filter === 'all' || transaction.type === filter
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '800px' }}>
      <Box sx={{ flexShrink: 0, mb: 2 }}>
        <SectionTitle>
          Extrato de Pontos - Loja Parceira
        </SectionTitle>
        <Divider sx={{ mb: 2 }} />
        {transactions.length == 0 ? "" :
        <FilterContainer>
          <FilterButton
            onClick={() => setFilter('all')}
            variant={filter === 'all' ? 'contained' : 'outlined'}
          >
            Todos
          </FilterButton>
          {transactions.filter((transaction) => transaction.type === 'redeem') != 0  && 
          <FilterButton
            onClick={() => setFilter('redeem')}
            variant={filter === 'redeem' ? 'contained' : 'outlined'}
          >
            Resgates
          </FilterButton> }
          {transactions.filter((transaction) => transaction.type === 'donation') != 0  && 
          <FilterButton
            onClick={() => setFilter('donation')}
            variant={filter === 'donation' ? 'contained' : 'outlined'}
          >
            Doações
          </FilterButton> }
        </FilterContainer> }
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
      {transactions.length == 0 ? 
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
              Ainda não há registros de compra ou venda de produtos na sua loja.
        </Typography>
        :
        <List>
          {filteredTransactions.map((transaction, index) => (
            <ListItem key={index} sx={{ borderBottom: '1px solid #ccc' }}>
              <ListItemText
                primary={`${transaction.points > 0 ? `+${transaction.points} pontos` : `${transaction.points} pontos`}`}
                secondary={
                  <>
                    {transaction.description}
                    <br />
                    <span style={{ fontSize: '0.875rem', color: '#999' }}>
                      {new Date(transaction.date).toLocaleString('pt-BR')}
                    </span>
                  </>
                }
                sx={{
                  color:
                    transaction.type === 'redeem' ? 'green' : 'red',
                  fontWeight: 'bold',
                }}
              />
            </ListItem>
          ))}
        </List> 
      }
      </Box>
    </Box>
  );
};

export default OperatorTransactionFilterCard;