import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from '@mui/material/styles'; 
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import theme from './config/theme'; 


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <StyledThemeProvider theme={theme}>
      <App />
      </StyledThemeProvider>
    </ThemeProvider>
    
  </React.StrictMode>,
  document.getElementById('root')
);