import React, { useEffect, useState } from 'react';
import { Typography, List, CircularProgress, Alert, Snackbar, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/apiService';
import { PageContainer, Container } from '../components/GlobalStyledComponents';
import { ActionButton } from '../components/ActionButton';
import StoreListItem from '../components/StoreListItem';
import Header from '../components/Header';

export default function PartnerList({ type, title, description, apiEndpoint, noItemsMessage }) {
  const [items, setItems] = useState([]);
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchItems();
    getLocation();
  }, []);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await apiService.get(apiEndpoint); // Usa o endpoint que for passado
      console.log(response)
      if (response) { 
        setItems(response); // Verifique se é uma lista
      } else {
        throw new Error('Resposta inválida da API');
      }
    } catch (error) {
      setError(`Não foi possível carregar os ${type}.`);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        () => {
          setError('Permissão para acessar a localização foi negada');
          setOpenSnackbar(true);
        }
      );
    }
  };

  const handleErrorClose = () => {
    setError('');
    setOpenSnackbar(false);
  };

  return (
    <PageContainer>
      <Header />
      <Container>
        <Typography variant="h4" component="h1" textAlign="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="text" component="text" textAlign="center" gutterBottom>
          {description}
        </Typography>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="200px">
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {items.length > 0 ? (
              items.map((item) => (
                <StoreListItem
                  key={item.id}
                  store={item} // Passa o objeto completo da loja ou ecoponto
                  location={location}
                  navigate={navigate}
                />
              ))
            ) : (
              <Typography variant="body1" textAlign="center">
                {noItemsMessage}
              </Typography>
            )}
          </List>
        )}

        <ActionButton label="Voltar à Home Page" onClick={() => navigate('/')} sx={{ mt: 3 }} />

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleErrorClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </Container>
    </PageContainer>
  );
}