// src/services/apiService.js

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = {
  get: async (endpoint, tokenEcoponto = null) => {
    const headers = tokenEcoponto ? { Authorization: `Bearer ${tokenEcoponto}` } : {};
    try {
      const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        const errorData = await response.json(); // Obter a mensagem de erro
        throw new Error(errorData.message || 'Erro ao carregar os dados.');
      }

      return await response.json(); // Retorna os dados se a requisição for bem-sucedida
    } catch (error) {
      console.error('Erro na requisição GET:', error.message);
      throw error; // Joga o erro para ser tratado no hook ou componente
    }
  },

  post: async (endpoint, data, tokenEcoponto = null) => {
    const headers = { 'Content-Type': 'application/json' };
    if (tokenEcoponto) {
      headers.Authorization = `Bearer ${tokenEcoponto}`;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json(); // Obter a mensagem de erro
        throw new Error(errorData.message || 'Erro ao registrar o descarte.');
      }

      return await response.json();
    } catch (error) {
      console.error('Erro na requisição POST:', error.message);
      throw error; // Joga o erro para ser tratado no hook ou componente
    }
  },

  put: async (endpoint, data, tokenEcoponto = null) => {
    const headers = { 'Content-Type': 'application/json' };
    if (tokenEcoponto) {
      headers.Authorization = `Bearer ${tokenEcoponto}`;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data),
      });


      if (!response.ok) {
        throw new Error(response.statusText || 'Erro na API');
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Erro na requisição PUT');
    }
  },

  delete: async (endpoint, tokenEcoponto = null) => {
    const headers = tokenEcoponto ? { Authorization: `Bearer ${tokenEcoponto}` } : {};
    try {
      const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
        method: 'DELETE',
        headers,
      });

      if (!response.ok) {
        throw new Error(response.statusText || 'Erro na API');
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || 'Erro na requisição DELETE');
    }
  },

  fetchEcopoints: async () => {
    const response = await fetch(`${API_BASE_URL}/ecopoints`);
    if (!response.ok) {
      throw new Error('Erro ao carregar os ecopontos');
    }
    return await response.json();
  },
  
  registerDisposal: async (cpf, ecopointId) => {
    const response = await fetch(`${API_BASE_URL}/disposals`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cpf, points: 10, ecopointId }),
    });
  
    if (!response.ok) {
      throw new Error('Erro ao registrar o descarte');
    }
  
    return await response.json();
  }
};



export default apiService;