import React from 'react';
import styled from 'styled-components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Ícone de "dinheiro" (ou outro ícone à escolha)

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;  /* Reduz o padding para ficar mais fino */
  background-color: transparent;  /* Sem fundo */
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.primary.main};  /* Texto na cor do tema */
  max-width: 500px;
  margin: 0 auto;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  h2 {
    margin: 0;
    font-size: 1.4rem; /* Reduz um pouco o tamanho da fonte */
    color: ${({ theme }) => theme.palette.text.primary};  /* Texto principal na cor primária */
  }

  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
    color: ${({ theme }) => theme.palette.text.secondary};  /* Texto secundário */
  }
`;

const BalanceContainer = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 1.4rem;
    margin-left: 8px;
    color: ${({ theme }) => theme.palette.primary.main};  /* Saldo na cor primária */
  }
`;

const UserCard = ({ name, balance }) => (
  <Card>
    <UserInfo>
      <h2>{name}</h2>
    </UserInfo>
    <BalanceContainer>
      <AttachMoneyIcon sx={{ fontSize: 30 }} />  {/* Ícone para o saldo */}
      <h2>{`${balance} pontos`}</h2>
    </BalanceContainer>
  </Card>
);

export default UserCard;