import React, { useState } from 'react';
import { Box, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/apiService';
import { Container, ButtonStyled, PageContainer } from '../components/GlobalStyledComponents';
import Header from '../components/Header';
import CPFInput from '../components/CPFInput'; // Importa o componente de CPF
import styled from 'styled-components';

const BackButtonStyled = styled(ButtonStyled)`
  background: none;
  color: ${({ theme }) => theme.palette.primary.main};
  border: none;
  &:hover {
    background: none;
    text-decoration: underline;
  }
`;

export default function RegisterRedemption() {
  const [cpf, setCpf] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [points, setPoints] = useState('');
  const [description, setDescription] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [redemptionResult, setRedemptionResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();
  const tokenEcoponto = localStorage.getItem('tokenEcoponto');

  // Remove máscara do CPF
  const removeCpfMask = (cpf) => cpf.replace(/\D/g, '');

  // Função para buscar nome do usuário pelo CPF
  const handleCpfSubmit = async () => {
    try {
      const cleanedCpf = removeCpfMask(cpf);
      const response = await apiService.get(`transfers/confirm-recipient/${cleanedCpf}`, tokenEcoponto);
      setRecipientName(response.name);
      setIsConfirmed(true);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Usuário não encontrado. Verifique o CPF.');
      setOpenSnackbar(true);
    }
  };

  // Função para confirmar o resgate
const handleRedemptionSubmit = async () => {
    try {
      const cleanedCpf = removeCpfMask(cpf); // Remove a máscara antes de enviar
      const response = await apiService.post('redemptions', {
        cpf: cleanedCpf, // Envia o CPF
        points: parseInt(points, 10),
        description, // Pode ser opcional
      }, tokenEcoponto);
  
      setRedemptionResult(response);
      setSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage('Erro ao realizar resgate. Verifique os dados.');
      setOpenSnackbar(true);
    }
  };

  // Fechar o diálogo de sucesso
  const handleCloseDialog = () => {
    setSuccessDialogOpen(false);
    navigate('/home-operador');
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleBack = () => {
    navigate(-1); // Navega para a página anterior
  };

  return (
    <PageContainer>
      <Header />
      <Container>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Registrar Resgate
        </Typography>

        {!isConfirmed ? (
          <>
            <Typography variant="body1" gutterBottom>
              Insira o CPF do usuário que vai realizar o resgate para confirmar o nome.
            </Typography>
            <CPFInput
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              error={errorMessage}
            />
            <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
              <BackButtonStyled onClick={handleBack} variant="text">Voltar</BackButtonStyled>
              <ButtonStyled onClick={handleCpfSubmit}>Confirmar CPF</ButtonStyled>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="body1" gutterBottom>
              Resgate de pontos para: <strong>{recipientName}</strong>
            </Typography>
            <TextField
              label="Quantidade de Pontos"
              variant="outlined"
              fullWidth
              value={points}
              onChange={(e) => setPoints(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Motivo do Resgate"
              variant="outlined"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
              <BackButtonStyled onClick={handleBack} variant="text">Voltar</BackButtonStyled>
              <ButtonStyled onClick={handleRedemptionSubmit}>Confirmar Resgate</ButtonStyled>
            </Box>
            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          </>
        )}

        {/* Diálogo de sucesso */}
        <Dialog open={successDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Resgate Realizado com Sucesso</DialogTitle>
          <DialogContent>
            <Typography>
              Nome do usuário: <strong>{redemptionResult?.recipient}</strong>
            </Typography>
            <Typography>
              Pontos resgatados: <strong>{redemptionResult?.pointsRescued}</strong>
            </Typography>
            <Typography>
              Motivo: <strong>{redemptionResult?.description}</strong>
            </Typography>
            <Typography>
              Data do resgate: <strong>{new Date(redemptionResult?.dateRedeemed).toLocaleString()}</strong>
            </Typography>
          </DialogContent>
          <DialogActions>
            <ButtonStyled onClick={handleCloseDialog}>Fechar</ButtonStyled>
          </DialogActions>
        </Dialog>

        {/* Snackbar de erro */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    </PageContainer>
  );
}