import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RecyclingIcon from '@mui/icons-material/Recycling';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import apiService from '../services/apiService';
import UserCard from '../components/userCard';
import { Container, PageContainer, ButtonStyled } from '../components/GlobalStyledComponents';
import QuickOptionsContainer from '../components/QuickOptionContainer';
import TransactionFilterCard from '../components/TransactionFilterCard';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Header from '../components/Header';

export default function Dashboard() {
  const [userName, setUserName] = useState('');
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const tokenEcoponto = localStorage.getItem('tokenEcoponto');

      try {
        const userInfo = await apiService.get('users/me', tokenEcoponto);
        setUserName(userInfo.name || '');
        setBalance(userInfo.points || 0);

        const disposals = await apiService.get('users/disposals', tokenEcoponto);
        const redemptions = await apiService.get('users/redemptions', tokenEcoponto);
        const donations = await apiService.get('users/donations', tokenEcoponto);
        const transfers = await apiService.get('users/transfers', tokenEcoponto); 
        const transfersRecieved = await apiService.get('users/recieved', tokenEcoponto); 

        const allTransactions = [
          ...disposals.map((disposal) => ({
            type: 'gain',
            description: `Descarte Seletivo no Ecoponto ${disposal.ecopoint?.name}`,
            points: disposal.points,
            date: new Date(disposal.createdAt),
          })),
          ...redemptions.map((redemption) => ({
            type: 'redeem',
            description: `Resgate na Loja ${redemption.store?.name}. Motivo: ${redemption.description}`,
            points: -redemption.points,
            date: new Date(redemption.createdAt),
          })),
          ...donations.map((donation) => ({
            type: 'donation',
            description: `Doação para loja ${donation.store?.name}. Motivo: ${donation.description}`,
            points: donation.value,
            date: new Date(donation.createdAt),
          })),
          ...transfers.map((transfer) => ({
            type: 'transfer',
            description: `Transferência realizada de ${transfer.points} pontos para ${transfer.recipient?.name}`,
            points: -transfer.points,
            date: new Date(transfer.createdAt),
          })),
          ...transfersRecieved.map((transfer) => ({
            type: 'transfer',
            description: `Transferência recebida de ${transfer.points} pontos feita por ${transfer.sender?.name}`,
            points: transfer.points,
            date: new Date(transfer.createdAt),
          }))
        ];

        const sortedTransactions = allTransactions.sort((a, b) => b.date - a.date);
        setTransactions(sortedTransactions);
        console.log(sortedTransactions);
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
        if (error.message === 'Unauthorized') {
          localStorage.removeItem('tokenEcoponto');
          navigate('/login');
        }
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('tokenEcoponto');
    navigate('/login');
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const filteredTransactions = transactions.filter(
    (transaction) => filter === 'all' || transaction.type === filter
  );

  const quickOptions = [
    { label: 'Transferir', icon: CurrencyExchangeIcon, onClick: () => handleNavigation('/transferencia') },
    { label: 'Ecopontos', icon: LocationOnIcon, onClick: () => handleNavigation('/ecopontos') },
    { label: 'Lojas', icon: ShoppingBagIcon, onClick: () => handleNavigation('/lojas-parceiras') }
  ];

  return (
    <PageContainer>
      <Header />
      <Container>
        <UserCard name={userName} balance={balance} />
        <QuickOptionsContainer options={quickOptions} />
        <TransactionFilterCard 
          transactions={transactions}
          filter={filter}
          setFilter={setFilter}
        />
      </Container>
    </PageContainer>
  );
}