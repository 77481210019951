import React from 'react';
import { Card, CardContent, Typography, CardMedia } from '@mui/material';
import UserCard from '../components/userCard';


const StoreInfoCard = ({ store }) => {
  const apiUpload = process.env.REACT_APP_API_SGR_UPLOAD;
  // const imageUrl = store.img ? `http://localhost:8080/uploads/${store.img}` : null;
  const imageUrl = store.img ? `${apiUpload}/${store.img}` : null;

  return (
    <Card sx={{ mb: 3 }}>
      {imageUrl && (
        <CardMedia
          component="img"
          height="140"
          image={imageUrl}
          alt={`Imagem da loja ${store.img}`}
        />
      )}
      <CardContent>
        <UserCard name={store.name} balance={store.points} />
        <Typography variant="body1">
          {store.description || 'Descrição não disponível'}
        </Typography>
        <Typography variant="body2" gutterBottom>
          Endereço: {store.address || 'Endereço não disponível'}
        </Typography>
        <Typography variant="body2" gutterBottom>
          Telefone: {store.phone || 'Telefone não disponível'}
        </Typography>
        <Typography variant="body2" gutterBottom>
          Email: {store.email || 'Email não disponível'}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StoreInfoCard;