import React from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  width: 100%;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 50px;
  margin-top: 1rem;
`;

export const ActionButton = ({ label, onClick, variant = 'contained' }) => (
  <StyledButton onClick={onClick} variant={variant}>
    {label}
  </StyledButton>
);