// /src/components/CPFInput.jsx
import React from 'react';
import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';

export default function CPFInput({ value, onChange, error }) {
  return (
    <InputMask
      mask="999.999.999-99"
      value={value}
      onChange={onChange}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          label="CPF"
          variant="outlined"
          fullWidth
          margin="normal"
          error={!!error}
          helperText={error}
        />
      )}
    </InputMask>
  );
}
