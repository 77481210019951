import React from 'react';
import PartnerList from '../components/PartnerLIst';

export default function PartnerStores() {
  return (
    <PartnerList
      type="lojas parceiras"
      title="Lojas Parceiras"
      apiEndpoint="partner-stores"
      noItemsMessage="Nenhuma loja parceira disponível no momento."
    />
  );
}