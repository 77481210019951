import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import PartnerStores from './pages/PartnerStores.jsx';
import StoreDetails from './pages/StoreDetails.jsx';
import PartnerEcopoints from './pages/PartnerEcopoints.jsx';
import TransferPoints from './pages/TransferPoints.jsx';
import UserSignup from './pages/UserSignup.jsx';
import OperatorHome from './pages/operadorHome.jsx';
import RegisterDonation from './pages/RegisterDonation.jsx';
import RegisterRedemption from './pages/RegisterRedemption.jsx';



function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }  />
        <Route path="/login" element={<Login />} />
        <Route 
          path="/descarte/:ecopointId" 
          element={
            <ProtectedRoute>
              <Register />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/lojas-parceiras" element={<PartnerStores />}
        />
        <Route 
          path="/store-details" 
          element={<StoreDetails />}
        />
        <Route 
          path="/ecopontos" 
          element={<PartnerEcopoints />}
        />
        <Route 
          path="/transferencia" 
          element={
            <ProtectedRoute>
            <TransferPoints />
            </ProtectedRoute>}
        />
      <Route 
          path="/cadastro" 
          element={ <UserSignup /> }
        />
      <Route 
          path="/home-operador" 
          element={<ProtectedRoute><OperatorHome /></ProtectedRoute>}
        />
      <Route 
          path="/registrar-doacao" 
          element={<ProtectedRoute><RegisterDonation /></ProtectedRoute>}
      />
      <Route 
          path="/registrar-compra" 
          element={<ProtectedRoute><RegisterRedemption /></ProtectedRoute>}
      />
      </Routes>
    </Router>
  );
}

export default App;
