import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@mui/material';

export const SuccessDialog = ({ open, onClose, userName, ecopointName, pointsGained, newBalance }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Descarte Registrado com Sucesso!</DialogTitle>
    <DialogContent>
      <Typography variant="body1" sx={{ marginBottom: '16px' }}>
        Parabéns, <strong>{userName}</strong>! Seu descarte no Ecoponto <strong>{ecopointName}</strong> foi registrado com sucesso.
      </Typography>
      <Typography variant="body1">
        Você ganhou <strong>{pointsGained} pontos</strong>.
      </Typography>
      <Typography variant="body1">
        Seu novo saldo é de <strong>{newBalance} pontos</strong>.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Fechar
      </Button>
    </DialogActions>
  </Dialog>
);