import React, { useState } from 'react';
import { Box, TextField, Typography, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiSGR from '../services/apiSGR';
import { Container, PageContainer } from '../components/GlobalStyledComponents';
import Header from '../components/Header';
import { ActionButton } from '../components/ActionButton';
import { jwtDecode } from 'jwt-decode'; // Remova as chaves na importação

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Por favor, insira um email válido.');
      setOpenSnackbar(true);
      return false;
    }
    if (password.length < 6) {
      setError('A senha deve ter pelo menos 6 caracteres.');
      setOpenSnackbar(true);
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    if (!validateForm()) return;

    try {
      const response = await apiSGR.post('login', { email, password });
      console.log(response.access_token);
      localStorage.setItem('tokenEcoponto', response.access_token);
      const tokenEcoponto = localStorage.getItem('tokenEcoponto');
      const decodedtokenEcoponto = jwtDecode(tokenEcoponto);
      const role = decodedtokenEcoponto.role;

      if (role === 2 || role === 5) {
        navigate('/home-operador');
      } else {
        navigate('/'); // Para usuários comuns
      }
    } catch (error) {
      if (error.response?.status !== 200) {
        setError('Usuário ou senha inválidos. Se esqueceu sua senha, entre em contato com contato@reciclesiri.com.br.');
      } else {
        const errorMessage = error.response?.data?.message || error.message || 'Erro ao conectar com o servidor.';
        setError(errorMessage);
      }
      setOpenSnackbar(true);
    }
  };

  const handleErrorClose = () => {
    setOpenSnackbar(false);
  };

  const handleSignupRedirect = () => {
    navigate('/cadastro'); // Redireciona para a tela de cadastro
  };

  return (
    <PageContainer>
      <Header />
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 3,
            maxWidth: '500px',
            margin: '0 auto',
            textAlign: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: '12px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Eco. - Economia Circular
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom sx={{ mb: 3 }}>
            Sistema de Pontuação - Você pode ganhar pontos doando itens ainda em condições de uso para a loja parceira ou descartando corretamente seus resíduos nos ecopontos parceiros.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            Faça Login
          </Typography>

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Senha"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <ActionButton
            label="Entrar"
            onClick={handleLogin}
            sx={{ marginBottom: '20px', width: '100%', maxWidth: '400px' }}
          />

          {/* Novo botão para redirecionar ao cadastro */}
          <Typography variant="body2" sx={{ marginTop: '10px' }}>
            Não tem uma conta? 
            <span style={{ color: '#009cd9', cursor: 'pointer' }} onClick={handleSignupRedirect}>
              Cadastre-se aqui
            </span>
          </Typography>
        </Box>

        {/* Snackbar de erro */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleErrorClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </Container>
    </PageContainer>
  );
};

export default Login;