import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

const QuickOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  height: 100px;
  margin: 10px;
`;

const OptionsCircle = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main}; /* Corrige para 'palette' */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SubtitleOption = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.primary.main}; /* Corrige para 'palette' */
  margin-top: 5px;
  text-align: center;
`;

const QuickOptionsContainer = ({ options }) => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',  // Para manter os itens em uma linha ou quebrar para baixo se a tela for pequena
      marginBottom: 3,
      gap: '20px', // Adiciona um espaçamento fixo entre os itens
      '@media (max-width: 768px)': {
        justifyContent: 'center',  // Centraliza em telas menores
        gap: '10px',
      }
    }}>
      {options.map(option => (
        <QuickOptions key={option.label}>
          <OptionsCircle onClick={option.onClick}>
            <option.icon sx={{ fontSize: 36, color: '#fff' }} />
          </OptionsCircle>
          <SubtitleOption>{option.label}</SubtitleOption>
        </QuickOptions>
      ))}
    </Box>
  );
};

export default QuickOptionsContainer;