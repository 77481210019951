import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const tokenEcoponto = localStorage.getItem('tokenEcoponto');

  if (!tokenEcoponto) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
