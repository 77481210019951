import styled from 'styled-components';

export const Container = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  margin: 5px;
  margin-top: 0px;

  @media (max-width: 768px) {
    padding: 12px;
  }

  @media (max-width: 480px) {
    padding: 8px;
    max-width: 100%;
  }
`;

export const ButtonStyled = styled.button`
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main}; /* Corrige para 'palette' */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.dark}; /* Corrige para 'palette' */
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 8px 16px;
    font-size: 12px;
    width: 100%;
  }
`;

export const PageContainer = styled.div`
  background-image: url('/images/fundo-totem.webp');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 480px) {
    font-size: 1.6rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.main}; /* Corrige para 'palette' */

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

export const ResponsiveFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

