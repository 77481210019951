import React from 'react';
import { Box, Button, List, ListItem, ListItemText, Divider,Typography } from '@mui/material';
import { styled } from '@mui/system';

const FilterButton = styled(Button)`
  padding: 6px 12px;
  font-size: 0.875rem;
  text-transform: none;
  margin: 4px;
  flex: 1 1 20%; /* Ajusta a largura para distribuir uniformemente */
  
  @media (max-width: 600px) {
    font-size: 0.75rem;
    flex: 1 1 45%; /* Em telas menores, reduz a largura */
  }
`;

const FilterContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Permite que os botões sejam ajustados em várias linhas */
  margin-bottom: 16px;
`;

const SectionTitle = styled(Box)`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 8px;
  text-align: center;
`;

const TransactionFilterCard = ({ transactions, filter, setFilter }) => {
  const filteredTransactions = transactions.filter(
    (transaction) => filter === 'all' || transaction.type === filter
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '800px' }}>
      {/* Seção de Filtro */}
      <Box sx={{ flexShrink: 0, mb: 2 }}>
        <SectionTitle>
          Extrato de Pontos
        </SectionTitle>
        <Divider sx={{ mb: 2 }} /> {/* Linha de separação */}
        {transactions.length === 0 ? "" :
        <FilterContainer>
          <FilterButton
            onClick={() => setFilter('all')}
            variant={filter === 'all' ? 'contained' : 'outlined'}
          >
            Todos
          </FilterButton>
          {transactions.filter((transaction) => transaction.type === 'gain') !== 0  && 
          <FilterButton
            onClick={() => setFilter('gain')}
            variant={filter === 'gain' ? 'contained' : 'outlined'}
          >
            Descartes
          </FilterButton> }
          {transactions.filter((transaction) => transaction.type === 'redeem') !== 0  && 
          <FilterButton
            onClick={() => setFilter('redeem')}
            variant={filter === 'redeem' ? 'contained' : 'outlined'}
          >
            Resgates
          </FilterButton> }
          {transactions.filter((transaction) => transaction.type === 'donation') !== 0  && 
          <FilterButton
            onClick={() => setFilter('donation')}
            variant={filter === 'donation' ? 'contained' : 'outlined'}
          >
            Doações
          </FilterButton> }
          {transactions.filter((transaction) => transaction.type === 'transfer' || transaction.type === 'transferRecieved') !== 0  && 
          <FilterButton
            onClick={() => setFilter('transfer')}
            variant={filter === 'transfer' || filter === 'transferRecieved' ? 'contained' : 'outlined'}
          >
            Transferências
          </FilterButton> }

        </FilterContainer>  
        }
      </Box>

      {/* Lista de transações com scroll */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {transactions.length === 0 ? 
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
              Oops... parece que você ainda não tem nenhuma ação. faça um descarte nos ecopontos parceiros ou doação para lojas parceiras para ganhar pontos.
        </Typography>
        :
        <List>
          {filteredTransactions.map((transaction, index) => (
            <ListItem key={index} sx={{ borderBottom: '1px solid #ccc' }}>
              <ListItemText
                primary={`${transaction.points > 0 ? `+${transaction.points} pontos` : `${transaction.points} pontos`}`}
                secondary={
                  <>
                    {transaction.description}
                    <br />
                    <span style={{ fontSize: '0.875rem', color: '#999' }}>
                      {new Date(transaction.date).toLocaleString('pt-BR')}
                    </span>
                  </>
                }
                sx={{
                  color:
                    transaction.type === 'gain'
                      ? 'green'
                      : transaction.type === 'redeem'
                      ? 'red'
                      : transaction.type === 'donation'
                      ? 'blue'
                      : transaction.type === 'transferRecieved'
                      ? 'green' : 'purple',
                  fontWeight: 'bold',
                }}
              />
            </ListItem>
          ))}
        </List>
      }
      </Box>
    </Box>
  );
};

export default TransactionFilterCard;