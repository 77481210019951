import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const HeaderContainer = styled.header`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Espaça os elementos igualmente */
  background-color: ${({ theme }) => theme.palette.primary.main};
  position: relative;
  min-height: 64px;

  @media (max-width: 768px) {
    padding: 12px;
  }

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

const Placeholder = styled.div`
  width: 120px; /* Mesma largura do logo para manter a centralização */
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const Logo = styled.img`
  max-width: 120px;
  height: auto;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05); /* Zoom ao passar o mouse */
  }

  @media (max-width: 768px) {
    max-width: 100px;
  }

  @media (max-width: 480px) {
    max-width: 80px;
  }
`;

const LogoutButton = styled(Button)`
  color: white !important;
  text-transform: none;
  background: none;

  &:hover {
    background: none;
    text-decoration: underline;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export default function Header() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('tokenEcoponto');
    navigate('/login');
  };

  const tokenEcoponto = localStorage.getItem('tokenEcoponto');

  return (
    <HeaderContainer>
      <Placeholder />
      <LogoContainer>
        <Logo src="/images/Logo-Siri-Branco.png" alt="Ecopoint Logo" />
      </LogoContainer>
      {tokenEcoponto && (
        <LogoutButton onClick={handleLogout}>
          Sair
        </LogoutButton>
      )}
    </HeaderContainer>
  );
}